<template>
  <div>配置</div>
</template>

<script>
export default {}
</script>

<style>
</style>
